<template>
  <section
    class="product_preview-head"
    :aria-label="$t('product.carousel.title')"
    @mouseover="isDesktopDevice && shouldOpenModalSizes()"
    @mouseleave="isDesktopDevice && closeModalSizes()">
    <PreviewImage
      :id="product.id"
      :object-id="product.objectId"
      :title="product.title"
      :carouselIsActive="carouselIsActive"
      :customUrl="customUrl"
      :url="product._base_url"
      :image="selectedImage"
      :algolia-data="product.algoliaData"
      :gridType="gridType"
      :product-position="productPosition"
      :retail-media-tracking="retailMediaTracking"
      :class-ratios="product._image_css_classes"
      @closeModalSizes="closeModalSize">
      <div v-if="isClientMounted && blockSelected !== 'supermarket'">
        <SelectorProductContainerDesktop
          v-if="!getRedirectToPDP"
          :showModal="showSelector && !isScrollingPLP && isOpenModalSizes"
          class="only_desktop"
          :product-uri="product.richRelevanceUrl ?? product._uri"
          :colors-collection="colorsCollection"
          :actual-stock="actualStock"
          :need-to-update-stock="needToUpdateStock"
          :grid-type="gridType"
          :redirectToPDP="product.redirectToPDP"
          :type-of-variant="typeOfVariant"
          :is-coming-soon="isComingSoon"
          @updateColor="handleColor"
          @sizeOvered="handleSizeOvered"
          @sizeLeft="handleSizeLeft"
          @sizeClicked="handleSize"
          @updateStock="handleStock" />

        <SelectorGoToPDP
          v-else
          :id="product.id"
          :algolia-data="product.algoliaData"
          :product-position="productPosition"
          :object-id="product.objectId"
          :showModal="showSelector && !isScrollingPLP && isOpenModalSizes"
          :product-uri="productUri" />
      </div>
    </PreviewImage>
  </section>
</template>

<script>
  import { throttle } from 'CommonUtils/operations/throttle';
  import StockSchema from 'CommonUtils/schemas/stock';

  import PreviewImage from './PreviewImage.vue';
  import SelectorGoToPDP from 'CommonComponents/Product/Selector/Desktop/SelectorGoToPDP.vue';

  import { mapGetter } from 'CommonUtils/store/state.js';
  import useNavigatorDetect from 'CommonComposables/navigatorDetect';

  export default {
    components: {
      PreviewImage,
      SelectorGoToPDP,
      SelectorProductContainerDesktop: defineAsyncComponent(() =>
        import('CommonComponents/Product/Selector/Desktop/SelectorProductContainerDesktop.vue'),
      ),
    },
    props: {
      actualStock: {
        type: Array,
        default: () => [],
      },
      colorsCollection: {
        type: Array,
        required: true,
      },
      currentProduct: {
        type: String,
        required: false,
        default: '',
      },
      gridType: {
        type: String,
        required: true,
      },
      isComingSoon: {
        type: Boolean,
        default: false,
      },
      needToUpdateStock: {
        type: Boolean,
        required: true,
      },
      product: {
        type: Object,
        required: true,
      },
      productPosition: {
        type: Number,
        required: false,
        default: undefined,
      },
      selectedImage: {
        type: [Array, String],
        required: true,
      },
      typeOfVariant: {
        type: String,
        required: true,
      },
      retailMediaTracking: {
        type: [String, undefined],
        default: undefined,
      },
    },
    emits: ['richRelevanceClicked', 'updateColor', 'updateSize', 'updateStock', 'sizeOvered', 'sizeLeft'],
    setup(props, { emit }) {
      const DEBOUNCE_TIME = 800;
      const isOpenModalSizes = ref(false);
      const openModalSizesFn = ref(false);
      const hasTriedToClose = ref(false);
      const isClientMounted = ref(false);
      const timer = ref(null);
      const { isDesktopDevice } = useNavigatorDetect();

      const crossSellingType = inject('crossSellingType', '');
      const isScrolling = inject('isScrolling', false);
      const productUrl = inject('productUri', '');

      const blockSelected = mapGetter('search-filters/getBlockSelected');

      const carouselIsActive = computed(() => {
        return !crossSellingType && !props.product.richRelevanceUrl;
      });
      const getRedirectToPDP = computed(() => {
        return props.product.redirectToPDP || props.product.isTicketsProduct;
      });
      const isScrollingPLP = computed(() => {
        if (isScrolling) return isScrolling();
        return false;
      });
      const showSelector = computed(() => {
        return !StockSchema.STATUS_BY_TYPE.NOT_STOCK_CHECK.includes(props.product?.stock?.status);
      });
      const customUrl = computed(() => {
        return (
          (props.product.sm_artwork?.show_custom_url && props.product.sm_artwork?.url) ||
          props.product.richRelevanceUrl ||
          productUrl.value
        );
      });

      const productUri = computed(() => {
        if (props.product.isTicketsProduct) {
          return props.product.product_url;
        }
        return props.product.richRelevanceUrl ?? productUrl.value;
      });

      const closeModalSize = () => {
        if (typeof openModalSizesFn.value === 'function') openModalSizesFn.value.cancel();
        clearTimeout(timer);
        timer.value = setTimeout(() => {
          isOpenModalSizes.value = false;
        }, 300);
      };
      const closeModalSizes = () => {
        // Si se llama a la función de cerrar modal antes de estar la variable de abrir modal a true,
        // entonces seteamos la variable hasTriedToClose a true,
        // para indicar que se ha intentado cerrar antes de abrir
        if (!isOpenModalSizes.value) return (hasTriedToClose.value = true);
        closeModalSize();
      };
      const handleColor = (color) => {
        emit('updateColor', color);
      };
      const handleSize = (size) => {
        emit('updateSize', size);
      };
      const handleSizeOvered = (size) => {
        emit('sizeOvered', size);
      };
      const handleSizeLeft = () => {
        emit('sizeLeft');
      };
      const handleStock = (stock) => {
        emit('updateStock', stock);
      };
      const shouldOpenModalSizes = () => {
        clearTimeout(timer);
        // Seteamos la flag hasTriedToClose a false cada vez que intentamos abrir la modal
        hasTriedToClose.value = false;

        if (isOpenModalSizes.value || !props.colorsCollection.length) return;

        openModalSizesFn.value = throttle(
          () => {
            // Si la flag hasTriedToClose es true cuando termina el tiempo de espera,
            // significa que se ha llamado al close modal antes de abrirlo,
            // entonces no hacemos nada
            if (hasTriedToClose.value) return;

            isOpenModalSizes.value = true;
          },
          DEBOUNCE_TIME,
          { leading: false },
        );

        openModalSizesFn.value();
      };

      onMounted(() => {
        isClientMounted.value = true;
      });

      return {
        carouselIsActive,
        closeModalSize,
        closeModalSizes,
        customUrl,
        getRedirectToPDP,
        handleColor,
        handleSize,
        handleSizeLeft,
        handleSizeOvered,
        handleStock,
        isClientMounted,
        isDesktopDevice,
        isOpenModalSizes,
        isScrollingPLP,
        productUrl,
        shouldOpenModalSizes,
        showSelector,
        productUri,
        blockSelected,
      };
    },
  };
</script>
