<template>
  <section
    :aria-labelledby="`${product.id}_product_preview_title`"
    class="product_preview-body">
    <div class="product_preview-ppal">
      <div class="product_preview flex-label">
        <PreviewInfo
          :id="product.id"
          :object-id="product.objectId"
          :customClass="getCustomClassForPreviewInfo"
          :title="product.title"
          :brand="product.brand || {}"
          :force-show-brand="forceShowBrand"
          :url="productUrl"
          :isCustomUrl="hasCustomUrl"
          :product-position="productPosition"
          :product-line="product._productLine"
          :algolia-data="product.algoliaData"
          :retail-media-tracking="retailMediaTracking"
          :eventLocation="product.event_location"
          :isTicketsProduct="product.isTicketsProduct"
          :eventDateFrom="product.event_date_from"
          :shouldHideBrand="shouldHideBrand" />
      </div>
      <div class="product_preview-buy">
        <div class="product_preview-buy-ppal">
          <div
            v-if="!product.sm_artwork?.hide_price"
            class="pricing js-preview-pricing">
            <Skeleton
              :should-show-content="showPrice"
              :should-force-hide-skeleton="forceHideSkeleton"
              :skeleton-class="'skeleton-product-preview-price'"
              :custom-style="'width: 30%;'">
              <template v-if="showPrice && !isGiftCard">
                <Price
                  v-if="priceSizeOvered && color && price?.unit_price"
                  :isProductPreview="true"
                  :item="priceSizeOvered"
                  :has-price-range="false"
                  :className="ticketsPriceClass" />
                <Price
                  v-else-if="color && price?.unit_price"
                  :item="price"
                  :isCollection="product._is_collection"
                  :isProductPreview="true"
                  :has-price-range="color.hasPriceRange"
                  :className="ticketsPriceClass" />
              </template>
            </Skeleton>
            <span
              v-if="!isAvailable && !isComingSoon"
              class="only_desktop pricing__not-available">
              {{ stockLabel }}
            </span>
          </div>
          <EnergyRating
            v-if="product.energy_rating"
            :energy-rating="product.energy_rating" />
          <UnitsSelector
            v-if="product.showUnits && isAvailable && !product.sm_artwork?.hide_units"
            :class="unitsSelectorClass"
            @unitsAdded="onProductUnitsUpdated"
            @unitsDecreased="onProductUnitsUpdated" />
        </div>

        <AvailableUnits
          v-if="!hasVariants && availableUnits"
          :availableUnits="availableUnits" />
      </div>
      <PreviewDates
        class="product_preview-ppal__dates"
        :previewDate="product.previewDate" />
      <PreviewLinkToPDP
        v-if="product.sofaConfigurator?.isEnabled"
        :id="product.id"
        :object-id="product.objectId"
        :algolia-data="product.alogiadData"
        :product-position="productPosition"
        :class="'product_preview-body--personalize'"
        :title="$t('product.sofa_configurator.personalize_yours')"
        :url="product.richRelevanceUrl ?? product._base_url"
        :isCustomUrl="Boolean(product?.richRelevanceUrl)" />
      <div class="only_desktop">
        <PreviewColorsDesktop
          v-if="colorsCollection.length > 1 && !product.sm_artwork?.hide_colors"
          :product_id="product.id"
          :colors-collection="colorsCollection"
          :is-pdp="false"
          :product-uri="product._uri"
          @updateColor="updateColor" />
      </div>
      <section
        v-if="product.sm_artwork?.type !== 'simple_product'"
        :aria-label="$t('product_preview.promos', product.title)"
        class="product_preview-body--promotions">
        <PromoBadgePLP
          v-if="
            (promoBadges || product.principal_promo?.length) && !hidePromotions && !product.sm_artwork?.hide_promotions
          "
          :badge-promotions="promoBadges || product.principal_promo"
          :productURL="product._uri" />
        <BadgeGif
          v-if="badgeGif && !hidePromotions && !product.sm_artwork?.hide_gif"
          :gif="badgeGif"
          :isPlp="true" />
        <BadgesAgrupation
          v-if="promoAgupation?.length && !hidePromotions"
          :badgesAgrupation="promoAgupation"
          :price="price" />
        <div
          v-if="customization?.show_delivery_message && stockDeliveryMessages.length"
          class="product_preview-body--delivery_message">
          <LongArrowRightIcon
            :width="'12'"
            :height="'12'"
            :heightViewBox="'12'" />
          <p
            v-for="(message, i) in stockDeliveryMessages"
            :key="i">
            {{ $t(message) }}
          </p>
        </div>
      </section>
      <div
        v-if="badges && badges.final && !product.sm_artwork?.hide_badges"
        :aria-label="$t('product.badges')"
        class="product_preview-body--badges">
        {{ badges.final }}
      </div>
      <ModoTienda
        v-if="shouldShowStoreMode"
        :product="product"
        :colors-collection="colorsCollection"
        :color="color"
        @updateSize="addToCart" />
      <div
        v-if="product._is_edig"
        class="amv-messages-container">
        <p class="amv-edig">
          E-DIG
        </p>
      </div>
      <div class="product_preview-body--soldby">
        <SoldByProvider
          v-if="showProviders"
          :product="product"
          :color="color"
          :actualStock="hasOnlyOneVariant ? actualStock : undefined"
          :sizeOvered="hasVariants ? sizeOvered : undefined"
          :isPLP="true"
          :show-additional-message="false" />
      </div>
      <div class="product_preview-body--flags">
        <BazaarVoice
          v-if="
            isMounted &&
              product.vendors?.BazaarVoice?.hasBazaarVoice &&
              !product.sm_artwork?.hide_bazaar_voice &&
              hasAcceptedCookies.VALORATION
          "
          :product-id="product.id"
          :type="BAZAAR_VOICE_TYPES.INLINE_RATING"
          @click="onBazaarClicked" />
        <ProductCompare
          v-if="
            isAvailable &&
              product.flags?.comparable &&
              !product.sm_artwork?.hide_comparator &&
              !customization?.hide_comparator
          "
          :product="product" />
      </div>
      <a
        v-if="product.sm_artwork?.type === 'simple_product'"
        class="product_preview-product_simple product_preview-cart"
        :href="productUrl">
        {{ $t('product_preview.simple_product_pdp_link') }}
      </a>
      <template v-else>
        <BtnColors
          v-if="shouldShowColorButton"
          :product="product"
          :grid-type="gridType"
          :colors-collection="colorsCollection" />
        <BtnBoth
          v-if="blockSelected !== 'supermarket'"
          :product="product"
          :isAvailable="isAvailable"
          :isComingSoon="isComingSoon"
          :isBookable="isBookable"
          :colorsCollection="colorsCollection"
          :grid-type="gridType"
          :isAddingToCart="isAddingToCart"
          @directAddToCart="addToCart" />
      </template>
    </div>
    <div
      v-if="showSelectCheckbox"
      class="filter-item__container">
      <a
        class="pointer"
        title="Seleccionar"
        role="checkbox"
        :aria-checked="isSelected"
        tabindex="0"
        @click.prevent="handleSelection"
        @keyup.enter.space="handleSelection"
        @keydown.prevent.space>
        <span class="item">
          <i
            class="item__check"
            :class="{ 'item__check--checked': isSelected }" />
          {{ $t('books.text_books.select') }}
        </span>
      </a>
    </div>
  </section>
</template>

<script>
  import { useI18n } from 'vue-i18n';
  import useNavigatorDetect from 'CommonComposables/navigatorDetect.js';
  import usePrice from 'Composables/usePrice.js';

  import { mapGetters, mapGetter } from 'CommonUtils/store/state.js';
  import PDPRedirect from 'CommonUtils/pdpRedirect.js';
  import StockSchema from 'CommonUtils/schemas/stock';
  import STOCK_TYPES from 'CommonUtils/schemas/stockTypes';
  import BAZAAR_VOICE_TYPES from 'Utils/bazaarVoiceTypes';
  import DELIVERY_TYPES_MESSAGES from 'Utils/deliveryTypesMessages';
  import PROVIDER_TYPE from 'Utils/providerType';
  import { getPromoAgrupationPLP, getPromoBadgesPLP } from 'CommonUtils/promotions/promotionsUtils';

  import BadgeGif from 'CommonComponents/BadgeGif/BadgeGif.vue';
  import BazaarVoice from 'Components/Vendors/BazarVoice/BazaarVoice.vue';
  import BtnBoth from './BtnBoth.vue';
  import BtnColors from './BtnColors.vue';
  import EnergyRating from 'CommonComponents/EnergyRating/EnergyRating.vue';
  import PreviewColorsDesktop from 'CommonComponents/Product/Selector/Color/PreviewColorsDesktop.vue';
  import PreviewDates from 'CommonComponents/Product/PreviewDates.vue';
  import PreviewInfo from 'Components/ProductPreview/PreviewInfo.vue';
  import PreviewLinkToPDP from 'Components/ProductPreview/PreviewLinkToPDP.vue';
  import Price from 'CommonComponents/Price/Price.vue';
  import ProductCompare from './ProductCompare.vue';
  import PromoBadgePLP from 'CommonComponents/PromoBadge/PromoBadgePLP.vue';
  import UnitsSelector from 'CommonComponents/UnitsSelector/UnitsSelector.vue';
  import AvailableUnits from 'Components/Stock/AvailableUnits.vue';
  import Skeleton from 'CommonComponents/Skeleton/Skeleton.vue';
  import { LongArrowRightIcon } from 'CommonComponents/UI/Atoms/Icons';
  import BadgesAgrupation from 'CommonComponents/BadgeAgrupation/BadgesAgrupation.vue';

  export default {
    name: 'ProductBody',
    components: {
      BadgesAgrupation,
      BadgeGif,
      BazaarVoice,
      BtnBoth,
      BtnColors,
      EnergyRating,
      ModoTienda: defineAsyncComponent(() => import('./ModoTienda.vue')),
      PreviewColorsDesktop,
      PreviewDates,
      PreviewInfo,
      PreviewLinkToPDP,
      Price,
      ProductCompare,
      PromoBadgePLP,
      SoldByProvider: defineAsyncComponent(() => import('Components/ProductDetail/ProvidersInfo/SoldByProvider.vue')),
      UnitsSelector,
      AvailableUnits,
      Skeleton,
      LongArrowRightIcon,
    },
    props: {
      product: {
        type: Object,
        required: true,
      },
      colorsCollection: {
        type: Array,
        required: true,
      },
      gridType: {
        type: String,
        required: true,
      },
      color: {
        type: Object,
        required: false,
        default: () => {},
      },
      badges: {
        type: Object,
        required: false,
        default: () => {},
      },
      forceShowBrand: {
        type: Boolean,
        default: false,
      },
      units: {
        type: Number,
        required: false,
        default: () => 1,
      },
      providers: {
        type: Array,
        required: false,
        default: () => [],
      },
      priceSizeOvered: {
        type: Object,
        required: false,
        default: () => null,
      },
      sizeOvered: {
        type: Object,
        required: false,
        default: () => null,
      },
      isAddingToCart: {
        type: Boolean,
        required: false,
        default: false,
      },
      retailMediaTracking: {
        type: [String, undefined],
        default: undefined,
      },
      showSelectCheckbox: {
        type: Boolean,
        required: false,
        default: false,
      },
      isGiftCard: {
        type: Boolean,
        default: false,
      },
      actualStock: {
        type: Array,
        required: false,
        default: () => [],
      },
      productPosition: {
        type: Number,
        required: false,
        default: undefined,
      },
    },
    emits: [
      'handleAddWishlist',
      'handleDeleteWishlist',
      'updateColor',
      'update:units',
      'updateSize',
      'toggleSelection',
      'updateStock',
    ],
    setup(props, { emit }) {
      const hidePromotions = inject('hidePromotions');
      const { product, color, providers, actualStock } = toRefs(props);
      const { t } = useI18n();
      const { price } = usePrice({ product, color });
      const { isDesktopDevice } = useNavigatorDetect();
      const isMounted = ref(false);
      const moduleName = mapGetter('filter/getActiveFilterModuleName').value;

      const { isStoreMode, customization, hasAcceptedCookies, hasOpenVariantSelector, giftsWithStock } = mapGetters(
        'page',
        {
          isStoreMode: 'isStoreMode',
          customization: 'getLayoutOptions',
          hasAcceptedCookies: 'hasAcceptedCookies',
          hasOpenVariantSelector: 'hasOpenVariantSelector',
          giftsWithStock: 'getGiftsWithStock',
        },
      );
      const { isSelectedClickAndCar, isSelectedDelivery, isSelectedDeliveryAndCollect, isSelected24Or48h } = mapGetters(
        moduleName,
        {
          isSelectedClickAndCar: 'getIsSelectedClickAndCar',
          isSelectedDelivery: 'getIsSelectedDelivery',
          isSelectedDeliveryAndCollect: 'getIsSelectedDeliveryAndCollect',
          isSelected24Or48h: 'getIsSelected24Or48h',
        },
      );
      const { blockSelected } = mapGetters('search-filters', {
        blockSelected: 'getBlockSelected',
      });
      const shouldShowStoreMode = computed(
        () => isStoreMode.value && !product.value.stock?.click_and_car && !product.value.stock?.eci_express,
      );
      const shouldShowColorButton = computed(
        () =>
          props.colorsCollection.length > 1 && (isDesktopDevice.value || !product.value.sofaConfigurator?.isEnabled),
      );
      const shouldHideBrand = computed(() => {
        return props.isGiftCard;
      });

      const isAvailable = computed(() => {
        if (color.value && 'not_available' in color.value) {
          return !color.value.not_available;
        }
        if (product.value.stock?.status) {
          return StockSchema.hasAvailableStatus(product.value.stock.status);
        }
        if (product.value._is_available) {
          return !color.value?.not_available;
        }
        return false;
      });

      const isBookable = computed(() => {
        if (!isAvailable.value) return;

        if (color.value?.status) {
          return color.value.status.toUpperCase() === StockSchema.STATUS.RESERVE;
        }
        if (color.value?.add_to_cart) {
          return color.value.add_to_cart.toUpperCase() === StockSchema.STATUS.RESERVE;
        }
      });

      const isComingSoon = computed(() => color.value?.coming_soon);
      const showPrice = computed(() => color.value?.showPrice || product.value?.isTicketsProduct);
      const forceHideSkeleton = computed(() => {
        return color.value?.status !== undefined || !StockSchema.shouldCheckStock(product.value?._status);
      });

      const stockLabel = computed(() => {
        if (!isAvailable.value) {
          const label = StockSchema.getStatusLabel(color.value?.add_to_cart);
          if (label) return t(label);
        }
        return t('product.sold_out');
      });

      const stockDeliveryMessages = computed(() => {
        const messages = [];

        if (!product.value?.stock || !DELIVERY_TYPES_MESSAGES) {
          return messages;
        }
        if (product.value.stock.type === STOCK_TYPES.V48) {
          messages.push(DELIVERY_TYPES_MESSAGES.fast_delivery);
          return messages;
        }
        if (isSelectedDelivery.value || isSelectedDeliveryAndCollect.value) {
          if (product.value.stock.eci_express === true) {
            messages.push(DELIVERY_TYPES_MESSAGES.fast_delivery);
            return messages;
          }
          return messages;
        }
        if (isSelectedClickAndCar.value || isSelected24Or48h.value) {
          return messages;
        }

        if (product.value.stock.eci_express === true) {
          messages.push(DELIVERY_TYPES_MESSAGES.fast_delivery);
        }

        return [...new Set(messages)];
      });

      const productUrl = computed(() => {
        const artworkUrl = product.value?.sm_artwork?.show_custom_url && product.value?.sm_artwork?.url;
        if (artworkUrl) {
          return artworkUrl;
        }
        if (product.value?.isTicketsProduct) {
          return product.value?.product_url ?? product.value?._base_url;
        }
        return product.value.richRelevanceUrl ?? product.value._base_url;
      });

      const hasCustomUrl = computed(() => {
        return Boolean(
          product.value?.richRelevanceUrl ||
            (product.value?.sm_artwork?.url && product.value.sm_artwork.show_custom_url),
        );
      });

      const customPrice = computed(() => {
        return product.value.sm_artwork?.price || undefined;
      });

      const hasVariants = computed(() => {
        return color.value?.variants?.length > 1;
      });

      const hasOnlyOneVariant = computed(() => {
        return color.value?.variants?.length === 1;
      });

      const availableUnits = computed(() => {
        return product.value.stock?.online_stock || 0;
      });

      const showOpenVariants = computed(() => {
        if (props.isGiftCard) {
          return !color.value?.variants?.[0]?.variant?.empty;
        }
        return hasOpenVariantSelector.value && !color.value?.variants?.[0]?.variant?.empty;
      });

      const unitsSelectorClass = computed(() => {
        return {
          'product_preview-buy__units-selector': true,
          'hide-mobile': showOpenVariants.value,
        };
      });

      const badgeGif = computed(() => {
        if (props.sizeOvered) {
          const targetAllPromos = props.sizeOvered?.all_promos;
          if (!Array.isArray(targetAllPromos)) {
            return [];
          }
          const gifPromo = targetAllPromos.find((promo) => {
            if (!promo.gifs_free_text) return false;
            if (
              promo.target_references?.length &&
              !promo.target_references.some((reference) => {
                const referenceId = reference.includes(':') ? reference.split(':')[0] : reference;
                return giftsWithStock.value.includes(referenceId);
              })
            ) {
              return false;
            }
            return promo.gifs_free_text;
          });
          if (gifPromo) {
            return {
              key: 'custom',
              description: '',
              type: 'url_img',
              url: gifPromo.gifs_free_text,
              promo_id: gifPromo.id,
              is_product_promo: Boolean(gifPromo.products),
            };
          } else {
            return {};
          }
        } else {
          if (Object.keys(props.product.principal_gif || {}).length && !props.product.principal_gif.isSPO) {
            return props.product.principal_gif;
          }
          return color.value?.principalGifColorVariant;
        }
      });

      const promoAgupation = computed(() => getPromoAgrupationPLP(props, color, giftsWithStock));
      const promoBadges = computed(() => getPromoBadgesPLP(props, color, giftsWithStock));

      const isBricor = computed(() => {
        if (!providers.value?.length) {
          return false;
        }
        return providers.value?.some((provider) => provider.provider_id === PROVIDER_TYPE.BRICOR);
      });

      const productHasStock = computed(() => {
        return actualStock.value?.length
          ? actualStock.value[0].stock?.some((variant) => variant.status === StockSchema.STATUS.ADD.toLowerCase())
          : product.value.stock?.status === StockSchema.STATUS.ADD.toLowerCase();
      });

      const showProviders = computed(() => {
        return (
          providers.value?.length &&
          productHasStock.value &&
          product.value.isSoldBy &&
          !product.value.sm_artwork?.hide_sold_by &&
          !isBricor.value
        );
      });

      const ticketsPriceClass = computed(() => {
        if (!props.product?.isTicketsProduct) {
          return '';
        }
        return 'product_ticket-price';
      });

      const getCustomClassForPreviewInfo = computed(() => {
        return props.isGiftCard ? 'product_preview-title-bold' : '';
      });
      const addToCart = (size, shippingSelected) => emit('updateSize', size, shippingSelected);
      const handleAddWishlist = (common_sku, productId) => emit('handleAddWishlist', common_sku, productId);
      const handleDeleteWishlist = (common_sku) => emit('handleDeleteWishlist', common_sku);
      const onBazaarClicked = () => (window.location.href = PDPRedirect.goToPDP(product.value, color.value?.title));
      const onProductUnitsUpdated = (newSelectedUnits) => emit('update:units', newSelectedUnits);
      const updateColor = (color) => emit('updateColor', color);
      const updateSize = (size) => emit('updateSize', size);

      const isSelected = ref(true);

      const handleSelection = () => {
        isSelected.value = !isSelected.value;
        emit('toggleSelection', isSelected.value);
      };

      onMounted(() => {
        isMounted.value = true;
        if (props.showSelectCheckbox && isSelected.value === true) {
          emit('toggleSelection', isSelected.value);
        }
      });

      return {
        BAZAAR_VOICE_TYPES,
        customization,
        hidePromotions,
        isAvailable,
        isBookable,
        isComingSoon,
        price,
        shouldShowStoreMode,
        stockDeliveryMessages,
        stockLabel,
        addToCart,
        handleAddWishlist,
        handleDeleteWishlist,
        onBazaarClicked,
        onProductUnitsUpdated,
        shouldHideBrand,
        shouldShowColorButton,
        updateColor,
        updateSize,
        productUrl,
        hasCustomUrl,
        customPrice,
        availableUnits,
        hasVariants,
        showPrice,
        isMounted,
        hasAcceptedCookies,
        forceHideSkeleton,
        showOpenVariants,
        unitsSelectorClass,
        badgeGif,
        promoBadges,
        ticketsPriceClass,
        isSelected,
        handleSelection,
        getCustomClassForPreviewInfo,
        hasOnlyOneVariant,
        showProviders,
        promoAgupation,
        blockSelected,
      };
    },
  };
</script>
