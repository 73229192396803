<template>
  <div
    class="product_preview-image"
    :class="classRatios">
    <a
      :class="linkClass"
      :aria-label="$t('product_preview.link_to_pdp', title)"
      :href="productUrl"
      :data-url="clickable ? url : null"
      @[clickable&&`click`]="onImageClicked">
      <CarouselImages
        :items="image"
        :is-server="isServer"
        :isDesktopDevice="isDesktopDevice"
        :carouselId="id"
        :active="carouselIsActive"
        @loaded="handledLoaded"
        @closeModalSizes="$emit('closeModalSizes')">
        <template #default="data">
          <LazyImage
            :key="getImageKey(data)"
            :image="data"
            :resolutions="resolutions"
            :product-position="productPosition"
            :title="title" />
        </template>
      </CarouselImages>
    </a>
    <slot></slot>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import CarouselImages from 'CommonComponents/Carousel/CarouselImages.vue';
  import UtilsURL from 'CommonUtils/url';
  import UtilsAnalytics from 'CommonUtils/analytics';
  import GRID_TYPES from 'CommonUtils/gridTypes';
  import LazyImage from './LazyImage.vue';
  import NavigatorDetect from 'CommonMixins/navigatorDetect.js';
  import UtilsImpolicyResolutions from 'CommonUtils/schemas/imageImpolicyResolutionsTypes.js';
  import { defer } from 'CommonUtils/operations/defer';
  import UtilsCookies from 'CommonUtils/cookies.js';
  import AlgoliaEvents from 'CommonUtils/algoliaEvents.js';

  import API from 'Services/API';
  const APIService = new API('/api/vuestore');

  export default {
    name: 'PreviewImage',
    components: {
      LazyImage,
      CarouselImages,
    },
    mixins: [NavigatorDetect],
    provide: function () {
      return {
        baseUrl: this.url,
      };
    },
    inject: {
      getDataLayer: { from: 'getDataLayer' },
      internals: { from: 'internals' },
      getCurrentSeletedColor: { from: 'getCurrentSeletedColor' },
      updateHistoryState: { from: 'updateHistoryState', default: () => {} },
      isRichRelevance: { from: 'isRichRelevance', default: false },
      crossSellingType: { from: 'crossSellingType' },
      isTicketsProduct: { from: 'isTicketsProduct', default: false },
      ticketProductUrl: { from: 'ticketProductUrl', default: false },
      productUri: { from: 'productUri', default: '' },
      currentPage: { from: 'currentPage' },
    },
    props: {
      carouselIsActive: {
        type: Boolean,
        default: true,
      },
      clickable: {
        type: Boolean,
        default: true,
      },
      customUrl: {
        type: String,
        required: false,
        default: undefined,
      },
      gridType: {
        type: String,
        default: GRID_TYPES.LITTLE_PRODUCTS,
      },
      id: {
        type: String,
        required: true,
      },
      objectId: {
        type: String,
        required: false,
        default: '',
      },
      image: {
        type: [Array, String],
        required: true,
      },
      productPosition: {
        type: Number,
        required: false,
        default: 1,
      },
      title: {
        type: String,
        required: true,
      },
      url: {
        type: String,
        required: true,
      },
      retailMediaTracking: {
        type: [String, undefined],
        default: undefined,
      },
      classRatios: {
        type: String,
        required: false,
        default: '',
      },
      algoliaData: {
        type: Object,
        default: () => undefined,
      },
    },
    emits: ['closeModalSizes'],
    data() {
      return {
        imgIsLoaded: false,
        imageSize: null,
        GRID_TYPES,
      };
    },
    computed: {
      ...mapGetters('page', {
        baseUrl: 'getBaseUrl',
        isServer: 'isServer',
        getSearchSource: 'getSearchSource',
        algoliaInfo: 'getAlgoliaInfo',
      }),
      ...mapGetters('cart', {
        algoliaUser: 'getAlgoliaUserData',
      }),
      resolutions() {
        const resolutions = UtilsImpolicyResolutions.PLP_RESOLUTIONS[this.gridType];
        if (!resolutions) {
          return UtilsImpolicyResolutions.PLP_RESOLUTIONS.big_products;
        }
        return resolutions;
      },
      productUrl() {
        if (!this.clickable) return null;
        if (this.isTicketsProduct) {
          return this.ticketProductUrl;
        }
        return this.customUrl ?? this.productUri;
      },
      linkClass() {
        return {
          product_link: true,
          pointer: this.clickable,
        };
      },
      hasToSendAlgoliaEvents() {
        return this.algoliaInfo?.enabled && this.getSearchSource === 'algolia';
      },
    },
    created() {
      AlgoliaEvents.initAlgoliaEventsUrl(this.algoliaInfo?.events);
    },
    methods: {
      onImageClicked() {
        if (this.retailMediaTracking) APIService.getRetailMediaTracking(this.retailMediaTracking);
        this.setProductIdInHistory();

        if (this.algoliaData?.query_id) {
          UtilsCookies.setCookie(
            'algolia_pdp',
            JSON.stringify({
              id: this.id,
              query_id: this.algoliaData?.query_id,
              index_name: this.algoliaData?.index_name,
              position: this.productPosition,
            }),
          );
        }
        if (this.hasToSendAlgoliaEvents) {
          const payload = {
            index: this.algoliaData?.index_name,
            queryID: this.algoliaData?.query_id,
            objectIDs: [this.objectId],
            positions: [this.productPosition],
            userToken: this.algoliaUser.algoliaUserToken,
            authenticatedUserToken: this.algoliaUser.algoliaAuthenticatedUserToken,
          };
          AlgoliaEvents.sendClickedObjectIDsAfterSearch(payload);
        }

        const deferredLinkToPDPClick = defer(UtilsAnalytics.sendProductPreviewLinkToPDPClick);
        deferredLinkToPDPClick(this.getDataLayer(), this.crossSellingType, this.isRichRelevance);
      },
      setProductIdInHistory() {
        if (this.updateHistoryState) this.updateHistoryState({ idProduct: this.id, page: this.currentPage });
      },
      getFullUrlWithQuery(data) {
        return UtilsURL.getFullUrlWithQuery(data);
      },
      handledLoaded(size) {
        this.imageSize = size;
      },
      getImageKey(data) {
        const item = data?.item;
        if (!item) return null;
        return `${item.big || item.zoom || item.medium || item.small || item.default}_${data.index}`;
      },
    },
  };
</script>
